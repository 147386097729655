<template>
  <div class="wrap">
    <div class="header">
      <div @click="$router.go(-1)">
        <svg-icon class="back" iconClass="whiteBack" />
      </div>
      <div class="recharge-box">
        <div class="points">积分:{{ balance }}</div>
        <div class="btn" @click="openRecharge">充值</div>
      </div>
    </div>
    <div class="go" @click="getLogin"></div>
    <van-popup class="recharge-popup" v-model="visibleRecharge" position="bottom">
      <div class="box">
        <div class="header">
          <h2>AI女友</h2>
          <p>充值积分</p>
        </div>
        <Loading v-if="isLoading" style="height: 166px" />
        <div v-else class="list">
          <div
            class="item"
            :class="{ active: index === currentIndex }"
            v-for="(item, index) in list"
            :key="item.id"
            @click="currentIndex = index"
          >
            <p class="coins">{{ item.coins }}积分</p>
            <p class="price">{{ item.price }}金豆</p>
          </div>
        </div>
        <div class="tips">
          <h3>支付小贴士：</h3>
          <p>1.因超时支付无法到账，请重新发起</p>
          <p>2.连续发起且未支付，账号可能被加入黑名单</p>
          <p>3.充值成功后会在1～5分钟内到账，可重新刷新进入当前页面</p>
        </div>
        <div class="btn" @click="exchange">{{ currentItem.price }}金豆/立即支付</div>
        <div class="service">如有疑问请咨询 <router-link tag="span" to="/customerService">在线客服</router-link></div>
      </div>
    </van-popup>

    <div class="mianBox" v-if="loginUrl">
      <div class="back" @click="closeUrl">退出</div>
      <iframe
        class="iframe"
        :src="loginUrl"
        frameborder="0"
        sandbox="allow-scripts allow-same-origin allow-forms"
      ></iframe>
    </div>
    <div v-if="isNoOpen" class="mask">
      <div @click="$router.go(-1)" class="back">
        <svg-icon iconClass="whiteBack" />
      </div>
      <div class="tips">{{ noOpenTip }}</div>
    </div>
  </div>
</template>
<script>
  import { getAimateGetBalance, getAimateLogin, getAimateCurrencys, postAimateExchange } from '@/api/mine';
  import Loading from '@/components/Loading';
  export default {
    name: 'AiGirlFriend',
    data() {
      return {
        visibleRecharge: false,
        balance: 0,
        isLoading: true,
        list: [],
        currentIndex: 0,
        loginUrl: '',
        isNoOpen: false,
        noOpenTip: '',
      };
    },
    components: {
      Loading,
    },
    computed: {
      currentItem() {
        if (this.list.length === 0) return { price: 0 };
        else return this.list[this.currentIndex];
      },
    },

    mounted() {
      this.getBalance();
    },
    methods: {
      getBalance() {
        this.$Api(getAimateGetBalance).then((res) => {
          const { tip, code, data } = res;
          this.balance = (data && data.balance) || 0;
          this.isNoOpen = code === 404;
          this.noOpenTip = tip;
        });
      },
      openRecharge() {
        this.visibleRecharge = true;
        if (this.list.length === 0) {
          this.isLoading = true;
          this.$Api(getAimateCurrencys)
            .then((res) => {
              this.list = res.data.list || [];
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      exchange() {
        this.$store.commit('app/SET_LOADING', true);
        this.$Api(postAimateExchange, {
          id: this.currentItem.id,
        })
          .then((res) => {
            if (res.code === 200) {
              this.getBalance();
              this.$toast('充值成功');
              this.visibleRecharge = false;
            } else if (res.code === 8000) {
              this.$router.push('rechargePage?t=gold');
            } else {
              this.$toast(res.tip);
            }
          })
          .finally(() => {
            this.$store.commit('app/SET_LOADING', false);
          });
      },
      getLogin() {
        this.$store.commit('app/SET_LOADING', true);
        this.$Api(getAimateLogin)
          .then((res) => {
            if (res.code === 200) {
              this.loginUrl = (res.data && res.data.url) || '';
            } else {
              this.$toast(res.tip);
            }
          })
          .finally(() => {
            this.$store.commit('app/SET_LOADING', false);
          });
      },
      closeUrl() {
        this.loginUrl = false;
        this.getBalance();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .wrap {
    width: 100%;
    height: 100%;
    background: url('../../../assets/png/AiGirlFriend-bg.png') #000 0 0/100% auto no-repeat;
    > .header {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 68px;
      background: url('../../../assets/png/AiGirlFriend-header.png') 0 100%/100% auto no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .back {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
      .recharge-box {
        margin-right: 16px;
        display: flex;
        padding: 2px 2px 2px 10px;
        align-items: center;
        border-radius: 100px;
        border: 1px solid #1e3c72;
        background: #000;

        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .btn {
        display: flex;
        padding: 3px 20px;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 100px;
        background: linear-gradient(90deg, #1e3c72 0%, #1e3c72 1%, #5f40b6 54.5%, #2a5298 100%);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
      }
    }

    > .go {
      width: 226px;
      height: 80px;
      position: fixed;
      top: 585px;
      left: 75px;
      background: url('../../../assets/png/AiGirlFriend-btn.png') 0 0/100% auto no-repeat;
    }

    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      .back {
        position: absolute;
        top: 16px;
        left: 16px;

        z-index: 2;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .tips {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .recharge-popup {
    border-radius: 20px 20px 0px 0px;
    background: #1d2236;
    width: 375px;
    min-height: 464px;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 18px 16px 0;
      color: #fff;
      .header {
        h2 {
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */
          margin-bottom: 12px;
        }
        p {
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      .list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        margin: 18px 0;
        .item {
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid, rgba(255, 255, 255, 0.1);
          background: rgba(255, 255, 255, 0.05);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.active {
            border-color: #30509c;
            background: #4039a1;
            .coins {
              color: #afabff;
            }
          }
          .coins {
            color: #7d95d5;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
          }
          .price {
            color: rgba(255, 255, 255, 0.3);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
      .tips {
        margin-left: 12px;
        h3 {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          margin-bottom: 6px;
        }
        p {
          color: rgba(255, 255, 255, 0.6);
          text-align: left;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
      .btn {
        margin: 18px 0;
        width: 343px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 44px;
        background: linear-gradient(90deg, #1e3c72 0%, #1e3c72 1%, #5f40b6 54.5%, #2a5298 100%), #ffc170;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      .service {
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        span {
          color: #4beaff;
        }
      }
    }
  }

  .mianBox {
    width: 100%;
    height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8000;
    .back {
      position: absolute;
      bottom: 66px;
      right: 16px;
      z-index: 8001;

      width: 48px;
      height: 48px;
      flex-shrink: 0;
      border-radius: 50%;
      background: green;
      line-height: 48px;
      text-align: center;
      color: #fff;
      font-size: 16px;
    }

    .iframe {
      width: 100%;
      height: 100%;
    }
  }
</style>
